import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export default function SocialBar(props: any) {
  return (
    <div
      id={props.anchorID}
      className="bg-opacity-25 bg-cover mt-8"
      style={{
        background: props.viewData.backgroundColor,
        backgroundImage:
          props.viewData.config.rgb !== undefined &&
          props.viewData.config.backgroundImageOpacity !== undefined
            ? `linear-gradient(rgba(${props.viewData.config.rgb}, ${props.viewData.config.rgb}, ${props.viewData.config.rgb}, ${props.viewData.config.backgroundImageOpacity}), 
        rgba(${props.viewData.config.rgb}, ${props.viewData.config.rgb}, ${props.viewData.config.rgb}, ${props.viewData.config.backgroundImageOpacity})), url(${props.viewData.config.backgroundImageURL})`
            : `url(${
                props.viewData.config
                  ? props.viewData.config.backgroundImageURL
                  : ""
              })`,
      }}
    >
      <div className="max-w-7xl py-8 px-4 sm:py-6 sm:px-6 lg:px-8 mx-auto">
        <div className="lg:grid lg:grid-cols-2 lg:gap-8">
          <h2
            style={{ color: props.viewData.titleColor }}
            className="uppercase max-w-md mx-auto text-2xl lg:text-3xl font-extrabold text-center lg:max-w-xl lg:text-left"
          >
            {props.viewData.title}
          </h2>
          <div className="mt-8 lg:mt-0 flex justify-center items-center">
            <div className="flex flex-row  md:space-y-0 md:flex-wrap justify-center  w-1/3">
              {props.viewData.socials.map((item: any) => (
                <div
                  key={item.url}
                  className="flex flex-grow flex-shrink-0 justify-center lg:flex-grow-0 lg:ml-6"
                >
                  <a
                    href={item.url}
                    rel="noreferrer noopener"
                    target="_blank"
                    className="block font-bold text-2xl space-x-2"
                  >
                    <FontAwesomeIcon
                      icon={["fab", item.title.toLowerCase()]}
                      color={props.viewData.iconColor}
                    />
                    {props.viewData.socialText && (
                      <span style={{ color: props.viewData.iconColor }}>
                        {item.title}
                      </span>
                    )}
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
