import React from "react";

export default function Label(props: any) {
  const {
    fontSize,
    paddingTop,
    paddingBottom,
    alignment,
    colorScheme,
    labelText,
  } = props.labelConfig;
  return (
    <div
      id={props.anchorID}
      style={{
        fontSize: fontSize + "px",
        paddingTop: paddingTop + "px",
        paddingBottom: paddingBottom + "px",
        textAlign: alignment,
        backgroundImage: colorScheme
          ? colorScheme
          : "linear-gradient(to right, #0063a8, #0081bc, #249ecc, #4ebbda, #78d8e7)",
        color: "black",
      }}
      dangerouslySetInnerHTML={{ __html: labelText }}
    />
  );
}
