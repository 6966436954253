import React, { useEffect, useState } from "react";
import BlogFAQItem from "./OCVBlogFAQItem";
import axios from "axios";

const BlogFAQ = (props: any) => {
  const { viewData, link, anchorID, showSearch } = props;
  const [blogData, setBlogData] = useState([]);
  const [search, setSearch] = useState("");
  useEffect(() => {
    fetch(link, { mode: "cors" })
      .then((res) => res.json())
      .then((res) => setBlogData(res))
      .catch((err) => console.error(err));
  }, [link]);

  const handleSearch = (e: any) => {
    setSearch(e.target.value);
  };
  return (
    <div id={anchorID} className="wrapper">
      <div
        className="bg-cover"
        style={{
          backgroundImage:
            props.viewData.config.rgb !== undefined &&
            props.viewData.config.backgroundImageOpacity !== undefined
              ? `linear-gradient(rgba(${props.viewData.config.rgb}, ${props.viewData.config.rgb}, ${props.viewData.config.rgb}, ${props.viewData.config.backgroundImageOpacity}), 
          rgba(${props.viewData.config.rgb}, ${props.viewData.config.rgb}, ${props.viewData.config.rgb}, ${props.viewData.config.backgroundImageOpacity})), url(${props.viewData.config.backgroundImageURL})`
              : `url(${
                  props.viewData.config
                    ? props.viewData.config.backgroundImageURL
                    : ""
                })`,
        }}
      >
        <div className="max-w-7xl mx-auto py-10 px-4 sm:py-16 sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto">
            {showSearch ? (
              <div className="p-2 w-10/12 sm:w-8/12 mx-auto h-10 mb-8">
                <input
                  type="search"
                  value={search}
                  onChange={handleSearch}
                  className="w-full rounded-full p-2"
                  placeholder={`Search ${viewData?.title}`}
                />
              </div>
            ) : null}
            <div className="mt-8">
              {blogData
                .filter((item: any) =>
                  item?.title?.toLowerCase().includes(search)
                )
                .map((post: any, i: number) => {
                  return (
                    i < viewData.config.limit && (
                      <BlogFAQItem key={i} title={post.title}>
                        <p
                          dangerouslySetInnerHTML={{ __html: post.content }}
                        ></p>
                        <div className="flex flex-row items-center justify-center  ">
                          {post.images.map((image: any, i: number) => (
                            <img
                              style={{ height: "300px", width: "250px" }}
                              src={image.small}
                              className="p-2"
                              alt={"Image for " + post.title}
                              key={image + i}
                            />
                          ))}
                        </div>
                      </BlogFAQItem>
                    )
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogFAQ;
