import React, { useState, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { Redirect, withRouter } from 'react-router';
import Swiper from 'react-id-swiper';
import * as moment from 'moment';
import Paper from '@material-ui/core/Paper/Paper';
import Lightbox from 'react-images';
import './OCVBlogDetail.css';
import { ApprissCharges } from '../../../../apprissCharges';
import ReactLoading from 'react-loading';

export default withRouter(function OCVBlogDetail(props: any) {
  const [currentImage, setCurrentImage] = useState(0);
  const [lightboxIsOpen, setLightbox] = useState(false);
  const [content, setContent] = useState<any>('');

  const { route, blogEntries, subtypes, history } = props;
  const openLightbox = (
    event: { preventDefault: () => void },
    index: number
  ) => {
    event.preventDefault();
    setCurrentImage(index);
    setLightbox(true);
  };
  const closeLightbox = () => {
    setLightbox(false);
  };
  const gotoPrevious = () => {
    setCurrentImage(currentImage - 1);
  };
  const gotoNext = () => {
    setCurrentImage(currentImage + 1);
  };

  const test = blogEntries.find((obj: any) => {
    return obj['_id']['$id'] === props['match']['params']['id'];
  });

  useEffect(() => {
    const charges = async () => {
      let chargesData = await ApprissCharges(test._id.$id);
      let content;
      if (test?.inmateID) {
        content = {
          ...test,
          content: test.content + '<br/>' + chargesData?.charges,
        };
        setContent(content);
      } else {
        content = {
          ...test,
        };
        setContent(content);
      }
    };
    charges().catch(console.error);
  }, [test]);

  let images: any[] = [];
  if (!content) {
  } else if (content.length) {
    for (let i = 0; i < content?.images.length; i++) {
      images.push({ src: content?.images[i].large });
    }
  }

  const params = {
    parallax: true,
    slidesPerView: 1,
    speed: 3000,
    loop: true,
    autoplay: {
      disableOnInteraction: true,
    },
    effect: 'fade',
  };

  if (!content) {
    return (
      <div className="OCVFormDiv">
        <ReactLoading
          className="loading-centered"
          type={'bars'}
          color={'#000'}
          height={'10%'}
          width={'10%'}
        />
      </div>
    );
  }

  return (
    <Row>
      {subtypes.includes('imageAtTop') && content.images.length !== 0 ? (
        <Col className="OCVBlogDetailColOne" xs={10} sm={8} md={4}>
          <Swiper {...params}>
            {content.images.map(
              (image: { large: string; small: string }, index: number) => (
                <img
                  alt={image.large}
                  onClick={(e) => openLightbox(e, index)}
                  className="object-contain"
                  key={index}
                  src={content.images.length !== 0 ? image.large : ' '}
                />
              )
            )}
          </Swiper>
          <Lightbox
            onClickNext={gotoNext}
            onClickPrev={gotoPrevious}
            backdropClosesModal
            currentImage={currentImage}
            onClose={closeLightbox}
            isOpen={lightboxIsOpen}
            images={images}
          />
        </Col>
      ) : (
        ''
      )}
      <Col className="OCVBlogDetailColTwo" xs={10}>
        {!subtypes.includes('noBackToList') && (
          <button
            className={'OCVBlogDetailButton btn btn-primary'}
            onClick={() => history.push('/' + route)}
          >
            &larr; Back to List
          </button>
        )}
        <Paper className="OCVBlogDetailPaper">
          <h1 className="font-semibold">{content.title}</h1>
          {'date' in content && !subtypes.includes('noDates') ? (
            <span className="OCVBlogDetailSpan">
              <p>
                Posted on{' '}
                {moment
                  .unix(content.date !== null ? content['date']['sec'] : '')
                  .format('LL')}
              </p>
            </span>
          ) : (
            ''
          )}
          <div
            dangerouslySetInnerHTML={{
              __html: content.content
                .replaceAll(
                  '<h6>',
                  '<h6 class="text-xs font-bold break-words">'
                )
                .replaceAll(
                  '<h5>',
                  '<h5 class="text-sm font-bold break-words">'
                )
                .replaceAll(
                  '<h4>',
                  '<h4 class="text-base font-bold break-words">'
                )
                .replaceAll(
                  '<h3>',
                  '<h3 class="text-xl font-bold break-words">'
                )
                .replaceAll(
                  '<h2>',
                  '<h2 class="text-2xl font-bold break-words">'
                )
                .replaceAll(
                  '<h1>',
                  '<h1 class="text-4xl font-bold break-words">'
                )
                .replaceAll('<ul>', '<ul class="list-disc m-2 p-2">')
                .replaceAll('<ol>', '<ol class="list-decimal m-2 p-2">')
                .replaceAll(
                  '<a',
                  '<a class="underline text-blue-800 break-words" '
                ),
            }}
          />
        </Paper>
      </Col>
      {!subtypes.includes('imageAtTop') && content.images.length !== 0 ? (
        <Col className="OCVBlogDetailColOne" xs={10} sm={8} md={4}>
          <Swiper {...params}>
            {content.images.map(
              (image: { large: string; small: string }, index: number) => (
                <img
                  alt={image.large}
                  onClick={(e) => openLightbox(e, index)}
                  key={index}
                  className="object-contain"
                  src={content.images.length !== 0 ? image.large : ' '}
                />
              )
            )}
          </Swiper>
          <Lightbox
            onClickNext={gotoNext}
            onClickPrev={gotoPrevious}
            backdropClosesModal
            currentImage={currentImage}
            onClose={closeLightbox}
            isOpen={lightboxIsOpen}
            images={images}
          />
        </Col>
      ) : (
        ''
      )}
    </Row>
  );
});
