import React from "react";
import { Row, Col } from "reactstrap";
import ocv from "../../../assets/OCV_Official.png";
import sheriffBlack from "../../../assets/SheriffBlack.png";
import ema from "../../../assets/Ema_logo_white.png";
import fire from "../../../assets/Fire_Long_V2.png";
import police from "../../../assets/Police_Long.png";
import publicHealth from "../../../assets/Public_Health_Long.png";
import { NavLink } from "react-router-dom";
import ListItem from "@material-ui/core/ListItem/ListItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import List from "@material-ui/core/List/List";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import "./Footer.css";

interface IVerticalMarket {
  [id: string]: {
    [id2: string]: any;
  };
}

export default function Footer(props: any) {
  // modify to include [footerConfig.vertical] and not just title
  const { classes, manifestData, footerConfig } = props;
  let verticalMarkets: IVerticalMarket = {
    sheriff: {
      href: "https://thesheriffapp.com", // dark option to be handled elsewhere
      alt: "The Sheriff App",
      img: sheriffBlack,
    },
    police: {
      href: "https://thepoliceapp.com",
      alt: "The Police App",
      img: police,
    },
    ema: {
      href: "https://myemaapp.com",
      alt: "My EMA App",
      img: ema,
    },
    fire: {
      href: "https://thefiredepartmentapp.com",
      alt: "The Fire Department App",
      img: fire,
    },
    health: {
      href: "https://thepublichealthapp.com",
      alt: "The Public Health App",
      img: publicHealth,
    },
    ocv: {
      href: "https://ocvapps.com",
      alt: "OCV Apps",
      img: ocv,
    },
  };

  const SimpleFooter = (classes: any, manifestData: any, footerConfig: any) => (
    <Row className={classes.footer + "mt-4"}>
      <Col lg={1} md={3}>
        <img
          className={classes.footerIcon}
          src={
            manifestData["stylesheet"].images
              ? manifestData["stylesheet"].images[footerConfig.icon]["url"]
              : ""
          }
          alt={
            manifestData["stylesheet"].images
              ? manifestData["stylesheet"].images[footerConfig.icon]["altText"]
              : ""
          }
        />
      </Col>
      <Col
        className={classes.columnA}
        style={{ paddingLeft: 0, paddingRight: 0 }}
        lg={6}
        md={5}
      >
        <div className={classes.footerInfo}>
          <h2 className="footerInfo">{footerConfig.title}</h2>
          <h5
            className="footerInfo"
            dangerouslySetInnerHTML={{ __html: footerConfig.address }}
          />
          <h5 className="footerInfo">Phone: {footerConfig.phone}</h5>
        </div>
      </Col>
      <Col
        className="columnB"
        style={{ paddingLeft: "20px", paddingRight: "20px" }}
        lg={5}
        md={4}
        sm={12}
      >
        <div className={classes.footerNav}>
          {footerConfig["menuItems"].map((item: string, index: number) =>
            index !== footerConfig["menuItems"].length - 1 ? (
              <NavLink
                key={item + index}
                className={classes.footerNavItem}
                to={"/" + item}
              >
                {manifestData["features"][item]["title"] + " / "}
              </NavLink>
            ) : (
              <NavLink
                key={item + index}
                className={classes.footerNavItem}
                to={"/" + item}
              >
                {manifestData["features"][item]["title"]}
              </NavLink>
            )
          )}
          <br />
          <h6 className="poweredBy">Powered by</h6>
          <a
            rel="noopener noreferrer"
            href={verticalMarkets[footerConfig.vertical].href}
            target={"_blank"}
          >
            <img
              className="footerImgOne"
              src={verticalMarkets[footerConfig.vertical].img}
              alt={footerConfig.vertical.alt}
            />
          </a>
        </div>
      </Col>
    </Row>
  );
  const ComplexFooter = (
    classes: any,
    manifestData: any,
    footerConfig: any
  ) => (
    <div className="mt-16 lg:mt-24">
      <Row
        className="complexFooterRowOne"
        style={{
          background:
            manifestData["stylesheet"]["colors"][
              footerConfig["phoneNumberCardBackground"]
            ],
        }}
      >
        <Col xs={12} key="col2" className="p-0">
          <div className="col2div">
            <Row>
              {footerConfig["menuItems"].map((item: string, index: number) => (
                <>
                  <Col
                    className={
                      "footerColOne d-lg-none flex items-center justify-center"
                    }
                    xs={6}
                    sm={4}
                    md={4}
                  >
                    <NavLink
                      key={item + index}
                      className={classes.footerNavItem}
                      to={"/" + item}
                    >
                      {manifestData["features"][item]["title"]}
                    </NavLink>
                  </Col>
                  <NavLink
                    className={classes.footerNavItem + " d-none d-lg-block"}
                    key={item + index}
                    to={"/" + item}
                  >
                    {manifestData["features"][item]["title"]}
                  </NavLink>
                </>
              ))}
            </Row>
          </div>
        </Col>
      </Row>
      <Row className={"complexFooterRowTwo " + classes.footer + "bg-white"}>
        <img
          className="footerImgTwo"
          src={
            manifestData["stylesheet"].images
              ? manifestData["stylesheet"].images[footerConfig.icon]["url"]
              : ""
          }
          alt={
            manifestData["stylesheet"].images
              ? manifestData["stylesheet"].images[footerConfig.icon]["altText"]
              : ""
          }
        />
        <Col className="footerColTwo" xs={10}>
          <h4
            style={{
              color: "#000",
              letterSpacing: "1px",
              fontSize: "1.3em",
            }}
            className="pb-3"
          >
            {footerConfig.title}
          </h4>
          <span
            className="text-base pb-2"
            dangerouslySetInnerHTML={{ __html: footerConfig.address }}
          />
          <a
            className="footerAnchorTwo"
            style={{
              color: "#000",
            }}
            href={"tel:" + footerConfig.phone}
          >
            {footerConfig.phone}
          </a>

          {footerConfig.businessHours && (
            <p
              style={{
                color: "#000",
              }}
              className="pb-6"
            >
              Hours: {footerConfig.businessHours}
            </p>
          )}
          <List className="footerList">
            {footerConfig["socialMedia"].map(
              (item: { type: IconName; link: string | undefined }) => (
                <ListItem
                  key={item.type}
                  className={classes.footerSocialListItem}
                >
                  <a
                    rel="noopener noreferrer"
                    target={"_blank"}
                    aria-label={item.type}
                    className={classes.topSocialLink}
                    href={item.link}
                  >
                    <FontAwesomeIcon size={"lg"} icon={["fab", item.type]} />
                  </a>
                </ListItem>
              )
            )}
          </List>
          <div className="flex flex-col md:flex-row justify-center text-black">
            <h6 className="footerH6 my-auto">
              Copyright &copy;{new Date().getFullYear()}. Powered by
            </h6>
            <a
              rel="noopener noreferrer"
              href={
                verticalMarkets[footerConfig.vertical]
                  ? verticalMarkets[footerConfig.vertical].href
                  : verticalMarkets["ocv"].href
              }
              target={"_blank"}
            >
              <img
                className="footerImgOne mx-auto"
                src={
                  verticalMarkets[footerConfig.vertical]
                    ? verticalMarkets[footerConfig.vertical].img
                    : verticalMarkets["ocv"].img
                }
                alt={footerConfig.vertical.alt}
              />
            </a>
          </div>
        </Col>
      </Row>
    </div>
  );

  return (
    <div id={props.anchorID}>
      {footerConfig["subtype"] === "simple" &&
        SimpleFooter(classes, manifestData, footerConfig)}
      {footerConfig["subtype"] === "complex" &&
        ComplexFooter(classes, manifestData, footerConfig)}
    </div>
  );
}
