// export async function ApprissCharges() {
//   return fetch(
//     'https://yiqvcldlxa.execute-api.us-east-1.amazonaws.com/dev/charges/22342462?appId=a42444113&stage=dev&agencyId=OHPICKAWAYSO&siteId=OHSWVINE',
//     { 'x-api-key': 'gchGGuTNck6bfpTwzkx9t71T4PYIgLj7o7y9oMh0' }
//   )
//     .then((response) => response.json())
//     .then((result) => result.charges)
//     .catch((error) => console.log('error', error));
// }

export const ApprissCharges = async (inmateID) => {
  let charges = await fetch(
    `https://yiqvcldlxa.execute-api.us-east-1.amazonaws.com/dev/charges/${inmateID}?appId=a42444113&stage=dev&agencyId=OHPICKAWAYSO&siteId=OHSWVINE`,
    { 'x-api-key': 'gchGGuTNck6bfpTwzkx9t71T4PYIgLj7o7y9oMh0' }
  );

  let chargesJson = await charges.json();
  return chargesJson;
};
