import React from "react";
import { Col, Row } from "reactstrap";
import Paper from "@material-ui/core/Paper/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { withRouter } from "react-router";
import "./OCVLargeSubmenuPage.css";

export default withRouter(function OCVLargeSubmenuPage(props: any) {
  const { items, manifestData, classes, history, anchorID } = props;

  return (
    <Row id={anchorID}>
      <Col className="OCVLargeSubmenuPageCol" xs={10}>
        <Paper className="OCVLargeSubmenuPagePaper">
          <List>
            {items.map((item: any) => {
              return (
                <div className="mb-8">
                  <h2 className="text-2xl font-bold text-center">
                    {item.title}
                  </h2>
                  {item.features.map((feature: any, i: any) =>
                    manifestData["features"][feature]["subtype"] ? (
                      manifestData["features"][feature]["subtype"][0] ===
                      "external" ? (
                        <a
                          style={{ textDecoration: "none" }}
                          target="_blank"
                          rel="noopener noreferrer"
                          href={manifestData["features"][feature]["url"]}
                        >
                          <ListItem
                            key={feature}
                            className={
                              "OCVLargeSubmenuPageListItem " +
                              classes.homepageButton
                            }
                          >
                            {manifestData["features"][feature]["title"]}
                          </ListItem>
                        </a>
                      ) : (
                        <ListItem
                          key={feature}
                          className={
                            "OCVLargeSubmenuPageListItem " +
                            classes.homepageButton
                          }
                          onClick={() => history.replace("/" + feature)}
                        >
                          {manifestData["features"][feature]["title"]}
                        </ListItem>
                      )
                    ) : (
                      <ListItem
                        key={feature}
                        className={
                          "OCVLargeSubmenuPageListItem " +
                          classes.homepageButton
                        }
                        onClick={() => history.replace("/" + feature)}
                      >
                        {manifestData["features"][feature]["title"]}
                      </ListItem>
                    )
                  )}
                </div>
              );
            })}
          </List>
        </Paper>
      </Col>
    </Row>
  );
});
